<template>
  <v-card outlined class="ma-2">
    <v-card-text>
      <v-row>
        <v-col xs="12">
          <v-progress-linear indeterminate color="primary" v-if="processing"></v-progress-linear>
          <v-subheader style="font-size: 16px!important;">Comisiones vs Chargeback</v-subheader>
          <div ref="amchart" style="height: 45vh;"></div>
          <br />
          <v-alert
            dense
            type="warning"
            text
            v-show="!processing && isChartEmpty"
          >No existe información para esta gráfica</v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import apiDashboard from "../../api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";
import { store } from "../../store";
import helper from "../../filters/helper";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["corteId", "tipoProducto"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      headers: [
        { text: "PERIODO", value: "Periodo" },
        { text: "CONCEPTO", value: "Concepto" },
        { text: "VALOR", value: "Monto" }
      ]
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";
        chart.scrollbarX = new am4core.Scrollbar();

        chart.data = dataParam;

  
    for(var j = 0; j < chart.data.length; j++){
        let comision = chart.data[j].Comision;
        let chargeback = chart.data[j].Chargeback;
        chart.data[j].pareto = (chargeback / comision) * 100;
        chart.data[j].valueChargeback = chargeback;
    } 

  // Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "Corte";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 60;
categoryAxis.tooltip.disabled = true;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.minWidth = 50;
valueAxis.min = 0;
valueAxis.cursorTooltipEnabled = false;

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.sequencedInterpolation = true;
series.name = "Comision"
series.dataFields.valueY = "Comision";
series.dataFields.categoryX = "Corte";
series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
series.columns.template.strokeWidth = 0;
series.stacked = true;

series.tooltip.pointerOrientation = "vertical";

series.columns.template.column.cornerRadiusTopLeft = 10;
series.columns.template.column.cornerRadiusTopRight = 10;
series.columns.template.column.fillOpacity = 0.8;

// on hover, make corner radiuses bigger
let hoverState = series.columns.template.column.states.create("hover");
hoverState.properties.cornerRadiusTopLeft = 0;
hoverState.properties.cornerRadiusTopRight = 0;
hoverState.properties.fillOpacity = 1;

// series.columns.template.adapter.add("fill", function(fill, target) {
//   return chart.colors.getIndex(target.dataItem.index);
// })


let paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
paretoValueAxis.renderer.opposite = true;
paretoValueAxis.min = 0;
paretoValueAxis.max = 100;
paretoValueAxis.strictMinMax = false;
paretoValueAxis.renderer.grid.template.disabled = true;
paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
paretoValueAxis.numberFormatter.numberFormat = "#'%'"
paretoValueAxis.cursorTooltipEnabled = false;

let paretoSeries = chart.series.push(new am4charts.LineSeries())
paretoSeries.dataFields.valueChargeback = "valueChargeback";
paretoSeries.dataFields.valueY = "pareto";
paretoSeries.dataFields.categoryX = "Corte";
paretoSeries.yAxis = paretoValueAxis;
paretoSeries.tooltipText = "chargeback: {valueChargeback}[/]\n{valueY.formatNumber('#.0')}%";
paretoSeries.name = "% chargeback"
paretoSeries.bullets.push(new am4charts.CircleBullet());
paretoSeries.strokeWidth = 2;
paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
paretoSeries.strokeOpacity = 0.5;

// Cursor
chart.cursor = new am4charts.XYCursor();
chart.cursor.behavior = "panX";


this.chart = chart;



      } catch (e) {
        console.log(e);
      }
    },
    refresh() {
      if (this.corteId) {
        this.processing = true;
        let param = {
          CorteId: this.corteId,
          TipoProductoList: Array.from(this.tipoProducto),
          Chart: "ComisionChargeback",
          AsociadoId: store.getters.user.asociado.id
        };

        apiDashboard
          .getChart(param)
          .then(response => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch(error => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getName(list, id, value, text) {
      return helper.getName(list, id, value, text);
    }
  },
  watch: {
    corteId: function() {
      this.refresh();
    },
    tipoProducto: function() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>